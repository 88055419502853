import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientSummary } from '../../../@types/client'
import { Stack, TextField, Checkbox, FormControlLabel, Alert } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { usePatchClientMutation } from '../../../services/clients'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type ClientUpdateFormProps = {
    client: ClientSummary;
    onUpdated(client: ClientSummary): void;
};

type ClientUpdateFormValues = {
    name: string;
    projectId: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    accountNumber: string | null;
    isCustomer: boolean;
    isSupplier: boolean;
    phoneNumber: string;
};

export const FormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Client name is required')
        .min(3),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    emailAddress: Yup.string().email('Email must be a valid email address').nullable(),
    accountNumber: Yup.string().nullable(),
});

export default function ClientUpdateForm({ client, onUpdated }: ClientUpdateFormProps) {

    const [patchClient] = usePatchClientMutation();

    const {
        reset,
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, isValid, errors }
    } = useForm<ClientUpdateFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            name: client.name,
            firstName: client.firstName,
            lastName: client.lastName,
            emailAddress: client.emailAddress,
            accountNumber: client.accountNumber,
            phoneNumber: client.phoneNumber,
            isCustomer: client.isCustomer,
            isSupplier: client.isSupplier,
        }
    })

    const onSubmit = async (data: ClientUpdateFormValues) => {

        try {
            var document = jsonpatch.deepClone(client);
            var observer = jsonpatch.observe<ClientSummary>(document);

            document.name = data.name;
            document.firstName = data.firstName;
            document.lastName = data.lastName;
            document.emailAddress = data.emailAddress;
            document.accountNumber = data.accountNumber;
            document.phoneNumber = data.phoneNumber;

            var patch = jsonpatch.generate(observer);

            const result = await patchClient({
                clientId: client.id,
                patch: patch
            }).unwrap();

            onUpdated(result);
        }
        catch (response) {
            console.log(errors)
            if (response.data && response.data.errors) {
                Object.keys(response.data.errors).forEach((key: any) => {
                    let errors: string[] = Object.keys(response.data.errors[key]).map((errorKey: string) => {
                        return response.data.errors[key][errorKey];
                    })

                    // set the erorrs on the form
                    setError(key, {
                        type: "manual",
                        message: errors.join(',')
                    })
                })
            } else {

            }
        }
    };

    return (
        <form>
            <Stack spacing={3}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Name"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="First Name"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Last Name"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="emailAddress"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Email"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="accountNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Account Number"
                            error={Boolean(error)}
                            helperText={error?.message}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <PhoneInput
                            country={'nz'}
                            disableDropdown={true}
                            value={value}
                            enableAreaCodes={true}
                            enableAreaCodeStretch
                            masks={{ nz: '(..) ...-......' }}
                            areaCodes={{ nz: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '21', '22', '20', '27'] }}
                            inputStyle={{
                                width: '100%',
                                //padding: '16.5px 14px'
                            }}
                            onChange={(value: any, data: any, event: any, formattedValue: any) => {
                                console.log(value)
                                console.log(data)
                                console.log(formattedValue)
                                onChange(formattedValue)
                            }}
                        />
                    )}
                />
                {
                    errors.projectId && <Alert severity="error" sx={{ mb: 3 }}>{errors.projectId.message}</Alert>
                }
                <LoadingButton
                    fullWidth
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isValid}
                >
                    Update Customer
                </LoadingButton>
            </Stack>
        </form>
    );
}
