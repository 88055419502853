import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dispatch, RootState, createSelector } from '../store';
import axios from '../../utils/axios';
import { VehicleState, VehicleUpdateRequest, VehicleComplianceConfiguration, VehicleServiceCreateRequest } from '../../@types/vehicle';
import { InvoiceStatus } from '../../@types/invoice';
import { Service, ServiceItem, ServiceItemRequest } from '../../@types/maintenance';
import { removeVehicle } from './vehicles'

const initialState: VehicleState = {
  recent: {
    isLoading: false,
    data: []
  },
  create: {
    selectedOwnerEntity: {
      id: '',
      name: '',
    },
    modalOpen: false,
  },
  isLoading: false,
  error: false,
  vehicle: null,
  invoices: {
    isFirstTime: true,
    isLoading: false,
    data: [],
    totalRows: 0,
    searchCriteria: {
      pageNumber: 0,
      pageSize: 25,
      order: 'desc',
      orderBy: 'dueDate',
      text: null,
      unlinkedOnly: false,
      statusFilter: [InvoiceStatus.Authorised, InvoiceStatus.Paid],
      typeFilter: [],
      projectIdentifier: undefined,
      vehicleId: ''
    }
  },
  service: {
    modalOpen: false,
    loading: true,
    data: null,
  },
  configuration: {
    compliance: {
      trackingEnabled: false,
    }
  }
};


export const deleteVehicle = createAsyncThunk(
  'vehicle/delete',
  async (vehicleId: string, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`/api/vehicle/${vehicleId}`);
      dispatch(removeVehicle(vehicleId))
      return vehicleId;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const slice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {

    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVehicleSuccess(state, action) {
      state.isLoading = false;
      state.vehicle = action.payload;
      state.invoices.isFirstTime = true;
    },

    setVehicleInvoiceSearch(state, action) {
      state.invoices.searchCriteria = action.payload;
    },

    loadVehicleService(state, action) {
      state.service.data = action.payload;
    },

    promptVehicleCreate(state, action) {
      state.create.modalOpen = true;
      state.create.selectedOwnerEntity = action.payload.selectedOwnerEntity;
    },

    toggleVehicleCreateModal(state, action) {
      state.create.modalOpen = action.payload;
    },

    toggleVehicleServiceModal(state, action) {
      state.service.modalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
 
  },
});

// Reducer
export default slice.reducer;

export const {
  setVehicleInvoiceSearch,
  toggleVehicleCreateModal,
  promptVehicleCreate,
  toggleVehicleServiceModal,
  loadVehicleService

} = slice.actions;

export function getVehicle(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/vehicle/${id}`);
      dispatch(slice.actions.getVehicleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// selectors
export const selectVehicleState = (state: RootState) => state.vehicle;
export const selectVehicleConfiguration = createSelector(selectVehicleState, state => state.configuration)
export const selectVehicleComplianceConfiguration = createSelector(selectVehicleConfiguration, state => state.compliance)

