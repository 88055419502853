import { useState, ReactElement } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Table,
  Switch,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Skeleton
} from '@material-ui/core';
import Scrollbar from '../shared/Scrollbar';
import SortingSelectingHead from './SortingSelectingHead';

export type TableHeader = {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    sortable: boolean;
    width?: number;
}

export type VeemTableProps<T> = {
    header: TableHeader[];
    data: T[];
    page: number;
    loading: boolean;
    totalRows: number;
    rowsPerPage: number;
    showSelectAll?: boolean;
    order: 'asc' | 'desc',
    orderBy: string,
    selectedRow?: string[];
    renderTableRow(row: T, selected: boolean, labelId: string) : ReactElement;
    getSelectedItemKey(row: T) : string;
    onRowSelected?(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) : void;
    setSelectRow?(rows: string[]) : void;
    handleChangePage(newPage: number): void;
    onRowsPerPageChange(newRowsPerPage: number): void;
    handleSort(orderBy: string, order: 'asc' | 'desc'): void;
    maxHeight?: any;
    defaultDense?: boolean;
}

export default function VeemTable<T>({
    header,
    data,
    page,
    loading,
    selectedRow = [],
    renderTableRow,
    getSelectedItemKey,
    rowsPerPage,
    totalRows,
    order,
    orderBy,
    onRowSelected,
    setSelectRow,
    handleChangePage,
    onRowsPerPageChange,
    handleSort,
    showSelectAll = false,
    maxHeight = 500,
    defaultDense = false } : VeemTableProps<T>) {

  //const [selected, setSelected] = useState<string[]>(selectedRow);
  const [dense, setDense] = useState(defaultDense);
  
  function handleSetRowSelect(selectRow: string[]) {
    if (setSelectRow) {
      setSelectRow(selectRow)
    } else {
     // setSelected(selectRow)
    }
  }

  function handleRequestSort(property: string) {
    const isAsc = orderBy === property && order === 'asc';
    handleSort(property, isAsc ? 'desc' : 'asc')
  };

  const isSelected = (row: T) => selectedRow.indexOf(getSelectedItemKey(row)) !== -1;
  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = data.map((n) => getSelectedItemKey(n));
      handleSetRowSelect(newSelecteds);
      return;
    }
    handleSetRowSelect([]);
  };

  async function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) => {
    const selectedIndex = selectedRow.indexOf(getSelectedItemKey(row));
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      //newSelected = newSelected.concat(selectedRow, getSelectedItemKey(row));
      newSelected = [getSelectedItemKey(row)]
    } 
    // else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selectedRow.slice(1));
    // } else if (selectedIndex === selectedRow.length - 1) {
    //   newSelected = newSelected.concat(selectedRow.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selectedRow.slice(0, selectedIndex),
    //     selectedRow.slice(selectedIndex + 1)
    //   );
    // }
    handleSetRowSelect(newSelected);

    if (onRowSelected) {
      onRowSelected(event, row);
    }
  };

  // Avoid a layout jump when reaching the last page with empty SORTING_SELECTING_TABLE.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;
  const skeletonRowCount = data.length === 0 ? rowsPerPage : data.length;

  const SkeletonComponent = () => (
    <TableBody>
        {Array.from({length: skeletonRowCount}).map((value) =>
          <TableRow
            key={`${uuidv4()}_TableRow`}
            style={{
              height: 30
            }
          }>
            <TableCell key={`${uuidv4()}_TableCell`} colSpan={header.length + 1} style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 } }>
              <Skeleton key={`${uuidv4()}_Skeleton`} height={40} component="div" />
            </TableCell>
          </TableRow>
        )}
    </TableBody>
  );

  return (
    <div>
        <Scrollbar>
          <TableContainer sx={{ maxHeight: maxHeight }}>
            <Table stickyHeader size={dense ? 'small' : 'medium'}>
              <SortingSelectingHead
                order={order}
                orderBy={orderBy}
                headLabel={header}
                numSelected={selectedRow.length}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                onSelectAllClick={handleSelectAllClick}
                showSelectAll={showSelectAll}
              />
              { loading ? <SkeletonComponent /> :
                <TableBody>
                  { data.map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          onMouseDown={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={getSelectedItemKey(row)}
                          selected={isItemSelected}
                          style={{
                            cursor: onRowSelected ? 'pointer' : 'default'
                          }}
                        >
                          {
                              renderTableRow(row, isItemSelected, labelId)
                          }
                        </TableRow>
                      );
                    })}
                      {/* {emptyRows > 0 && (
                      <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows
                          }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                  )} */}
                </TableBody>
                }
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            sx={{
              px: 3,
              py: 1.5,
              top: 0,
              position: { md: 'absolute' }
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={dense}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDense(event.target.checked)
                  }
                />
              }
              label="Dense padding"
            />
          </Box>
        </Box>
    </div>
  );
}
