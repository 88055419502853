import { TableCell } from '@material-ui/core';
import { ComplianceNotification, ComplianceNotificationAudit } from '../../../../@types/compliance'
import VeemTable, { TableHeader } from '../../../table/VeemTable';
import { fDateTime } from '../../../../utils/formatTime'

const TABLE_HEAD = [
    {
        id: 'whenNotificationSent',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        sortable: false,
        width: 190
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: false,
    },
    {
        id: 'message',
        numeric: false,
        disablePadding: false,
        label: 'Message',
        sortable: false,
    }
  ];

type ComplianceReminderHistoryTableProps = {
    complianceNotification: ComplianceNotification;
}

export default function ComplianceReminderHistoryTable({ complianceNotification }: ComplianceReminderHistoryTableProps) {

    return (
        <VeemTable<ComplianceNotificationAudit>
            header={TABLE_HEAD}
            data={complianceNotification.notifications}
            page={0}
            loading={false}
            totalRows={complianceNotification.notifications.length}
            rowsPerPage={10}
            showSelectAll={false}
            getSelectedItemKey={(row: ComplianceNotificationAudit) => row.whenNotificationSent.toString()}
            order={"asc"}
            orderBy={"whenNotificationSent"}
            handleChangePage={() => {}}
            handleSort={() => {}}
            onRowsPerPageChange={() => {}}
            renderTableRow={(row: ComplianceNotificationAudit, selected: boolean, labelId: string) => {
                return (
                    <>
                        <TableCell>{fDateTime(row.whenNotificationSent)}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.message}</TableCell>
                    </>
                )
            }}
        />
    );
}
