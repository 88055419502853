import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack5';
import { Stack, Typography, Checkbox, FormControlLabel, TextField, Paper, Box, Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import axios from '../../../utils/axios'
import { useDispatch } from '../../../redux/store';
import * as Yup from 'yup';
import { setConfiguration } from '../../../redux/slices/vehicles'
import { UserGroupSelectOption,  } from '../../../@types/search'
import { ComplianceTypeConfiguration, ComplianceType } from '../../../@types/compliance'

type ComplianceSettingsFormProps = {
    projectIdentifer: string;
    complianceConfiguration: ComplianceTypeConfiguration;
    complianceTypeName: string;
    complianceTypeCode: 'WOF' | 'COF';
  };

type ComplianceSettingsFormValues = {
    createComplianceReminder: boolean;
    complianceTrackingEnabled: boolean;
    processingEnabled: boolean;
    emailReminderFromName: string;
    emailRemindersEnabled: boolean;
    emailReminderSendToClient: boolean;
    emailReminderBody: string;
    reminderAdditionalEmail: string;
    reminderReplyToEmail: string;
    defaultComplianceAllocation: UserGroupSelectOption;
  };

export const FormSchema = Yup.object().shape({
    defaultComplianceAllocation: Yup.object().nullable(),
    createComplianceReminder: Yup.boolean().required(),
    complianceTrackingEnabled: Yup.boolean().required(),
    processingEnabled: Yup.boolean().required(),
    emailReminderFromName: Yup.string().nullable(),
    emailRemindersEnabled: Yup.boolean().required(),
    emailReminderSendToClient: Yup.boolean().required(),
    emailReminderBody: Yup.string().nullable(),
    reminderAdditionalEmail: Yup.string().nullable(),
  });

export default function ComplianceSettingsForm({ projectIdentifer, complianceConfiguration, complianceTypeName, complianceTypeCode } : ComplianceSettingsFormProps) {
  
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<ComplianceSettingsFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      createComplianceReminder: true,
      complianceTrackingEnabled: complianceConfiguration.trackingEnabled,
      processingEnabled: complianceConfiguration.enabled,
      emailRemindersEnabled: complianceConfiguration.reminderEmailConfiguration.enabled,
      emailReminderBody: complianceConfiguration.reminderEmailConfiguration.emailBody,
      emailReminderSendToClient: complianceConfiguration.reminderEmailConfiguration.sendReminderToClient,
      emailReminderFromName: complianceConfiguration.reminderEmailConfiguration.reminderFromName,
      reminderAdditionalEmail: complianceConfiguration.reminderEmailConfiguration.reminderAdditionalEmail,
      reminderReplyToEmail: complianceConfiguration.reminderEmailConfiguration.reminderReplyToEmail,
      // defaultComplianceAllocation: {
      //     id: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationId || '',
      //     name: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationName  || '',
      //     type: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationType,
      // }
    }
  })

  const emailRemindersEnabledWatch = watch('emailRemindersEnabled');

  const onSubmit = async (data: ComplianceSettingsFormValues) => {
    
    try {
      // post the configuration
      var response = await axios.put<any, any>(`/api/projects/${projectIdentifer}/compliance-config`, {
        complianceType: complianceTypeCode == "WOF" ? ComplianceType.WOF : ComplianceType.COF,
        createComplianceReminder: data.createComplianceReminder,
        complianceTrackingEnabled: data.complianceTrackingEnabled,
        processingEnabled: data.processingEnabled,
        emailRemindersEnabled: data.emailRemindersEnabled,
        emailReminderBody: data.emailReminderBody,
        emailReminderSendToClient: data.emailReminderSendToClient,
        emailReminderFromName: data.emailReminderFromName,
        reminderAdditionalEmail: data.reminderAdditionalEmail,
        reminderReplyToEmail: data.reminderReplyToEmail,
        // defaultReminderAllocation: {
        //   allocationId: data.defaultComplianceAllocation.id,
        //   allocationType: data.defaultComplianceAllocation.type,
        // },
      });
      
      // load the new configuration state
      dispatch(setConfiguration(response.data));
      enqueueSnackbar('Vehicle settings updated', { variant: 'success' });
      
      var repsonseData = complianceTypeCode == "WOF" ? response.data.complianceConfiguration.wofConfiguration : response.data.complianceConfiguration.cofConfiguration;
      reset({
        createComplianceReminder: true,
        complianceTrackingEnabled: repsonseData.trackingEnabled,
        processingEnabled: repsonseData.enabled,
        emailRemindersEnabled: repsonseData.reminderEmailConfiguration.enabled,
        emailReminderBody: repsonseData.reminderEmailConfiguration.emailBody,
        emailReminderSendToClient: repsonseData.reminderEmailConfiguration.sendReminderToClient,
        emailReminderFromName: repsonseData.reminderEmailConfiguration.reminderFromName,
        reminderAdditionalEmail: repsonseData.reminderEmailConfiguration.reminderAdditionalEmail,
        reminderReplyToEmail: repsonseData.reminderEmailConfiguration.reminderReplyToEmail,
        // defaultComplianceAllocation: {
        //     id: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationId || '',
        //     name: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationName  || '',
        //     type: complianceConfiguration.reminderConfiguration.defaultAllocation.allocationType,
        // }
      });
    }
    catch (err) {
      enqueueSnackbar('Error occurred updating vehicle settings', { variant: 'error' });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mb: 5}}>
          <Grid item xs={12} md={4} sm={6}>
            <Paper
              sx={{
                p: 2,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1">General Settings</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Compliance Processing</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                If this feature is enabled, when a vehicle is created the latest compliance information will be 
                populated automatically (i.e the latest {complianceTypeCode} details including the expiry of date of the last inspection)
              </Typography>
              <Controller
                    name="processingEnabled"
                    control={control}
                    render={({ field }) => (
                    <FormControlLabel
                        label={`Enable ${complianceTypeName} Processing`}
                        control={
                        <Checkbox
                            {...field}
                            onChange={e => field.onChange(e.target.checked)}
                            checked={field.value}
                        />
                        }
                    />
                    )}
                  />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 5}}>
          <Grid item xs={12} md={4} sm={6}>
            <Paper
              sx={{
                p: 2,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1">Tracking and Notifications</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Typography variant="subtitle1">Compliance Tracking</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Compliance tracking allows vehicle compliances to subscribe to updates from NZTA and ensures that all compliance information is kept up to date automatically. This feature may incur additional costs if subscription thresholds are exceeded. 
              </Typography>
              <Controller
                name="complianceTrackingEnabled"
                control={control}
                render={({ field }) => (
                <FormControlLabel
                    label="Compliance Tracking Enabled"
                    control={
                    <Checkbox
                        {...field}
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value}
                    />
                    }
                />
                )}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Email Reminders</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Reminders are sent when a vehicle compliance is near its due date. This feature should be enabled if you provide 
                  compliance check services so that your clients know when to bring their vehicle to you for an inspection.
              </Typography>
              <Controller
                  name="emailRemindersEnabled"
                  control={control}
                  render={({ field }) => (
                  <FormControlLabel
                      label={`Enable ${complianceTypeCode} Email Reminders`}
                      control={
                      <Checkbox
                          {...field}
                          onChange={e => field.onChange(e.target.checked)}
                          checked={field.value}
                      />
                      }
                  />
                  )}
              />
              { emailRemindersEnabledWatch ?
                  <>
                      <Controller
                        name="emailReminderSendToClient"
                        control={control}
                        render={({ field }) => (
                        <FormControlLabel
                            label={`Send ${complianceTypeCode} Reminder To Client`}
                            control={
                            <Checkbox
                                {...field}
                                onChange={e => field.onChange(e.target.checked)}
                                checked={field.value}
                            />
                            }
                        />
                        )}
                      />
                      <Controller
                        name="emailReminderFromName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="outlined-multiline-static"
                            label={`${complianceTypeCode} Email From Address`}
                            helperText="This will set the from name for the reminder email that is sent. If not set it will use the name of the project."
                        />
                        )}
                      />
                      <Controller
                        name="reminderReplyToEmail"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="outlined-multiline-static"
                            label="ReplyTo Email Address"
                            helperText="This is the email address that will be used when the client replies to the notification. If this is not supplied the project email will be used"
                        />
                        )}
                      />
                      <Controller
                        name="reminderAdditionalEmail"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="outlined-multiline-static"
                            label="Additional Email Address"
                            helperText="This is an additional email address to send the reminder to. This will normally be sent to an internal email and used to track the reminders that are being sent to clients."
                        />
                        )}
                      />
                      <Controller
                        name="emailReminderBody"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="outlined-multiline-static"
                            label={`${complianceTypeCode} Email Body (optional)`}
                            helperText={`The email will highlight the details of the vehicle and when the ${complianceTypeCode} is due. Populate this section with details about how your client should get in touch to book the inspection`}
                            multiline
                            rows={4}
                        />
                        )}
                      />
                  </>
                  : null }
            </Stack>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} sx={{ mb: 5}}>
          <Grid item xs={12} md={4} sm={6}>
            <Paper
              sx={{
                p: 2,
                width: 1,
                bgcolor: 'background.neutral'
              }}
            >
              <Typography variant="subtitle1">Workflow</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} sm={6}>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Task Reminders</Typography>
              <Typography variant="body2">
                When a compliance is created by the system, you have the option to create a task for the given compliance to use as a reminder
              </Typography>
              <Controller
                  name="createComplianceReminder"
                  control={control}
                  render={({ field }) => (
                  <FormControlLabel
                      label="Create Task Reminders"
                      control={
                      <Checkbox
                          {...field}
                          onChange={e => field.onChange(e.target.checked)}
                          checked={field.value}
                      />
                      }
                  />
                  )}
              />
              { createComplianceReminderValue ?
                <>
                    <Typography variant="body2">
                        When a task reminder is generated, it can be allocated to a specific person or group. By
                        default the task will have no allocation.
                    </Typography>
                    <Controller
                        name="defaultComplianceAllocation"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                        <ProjectUserAndGroupSelectList
                            label={'Default Assignee (User or Group)'}
                            field={field}
                        />
                        )}
                    />
                </>
                : null }
            </Stack>
          </Grid>
        </Grid> */}
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            Update Settings
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}
