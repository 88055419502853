import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 700 700" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M689.449 233.876L633.834 330.204C627.582 341.033 627.582 354.375 633.834 365.204L689.449 461.532M115.344 130.828L226.574 130.828C239.078 130.828 250.633 124.157 256.885 113.328L312.5 16.9999M312.5 679.514L256.885 583.186C250.633 572.357 239.078 565.686 226.574 565.686H115.344" stroke="#C5C5C5" strokeWidth="34" strokeLinecap="round" />
        <path d="M252.842 222.11L320.259 338.881C323.475 344.45 323.475 351.312 320.259 356.881L252.842 473.652C249.626 479.221 243.684 482.652 237.253 482.652H102.418C95.987 482.652 90.0447 479.221 86.8293 473.652L19.4115 356.881C16.1962 351.312 16.1962 344.45 19.4115 338.881L86.8293 222.11C90.0447 216.541 95.987 213.11 102.418 213.11H237.253C243.684 213.11 249.626 216.541 252.842 222.11ZM556.383 397.36L623.801 514.131C627.016 519.7 627.016 526.561 623.801 532.131L556.383 648.901C553.167 654.471 547.225 657.902 540.794 657.902H405.959C399.528 657.902 393.586 654.471 390.37 648.902L322.953 532.131C319.737 526.561 319.737 519.7 322.953 514.131L390.37 397.36C393.586 391.79 399.528 388.36 405.959 388.36H540.794C547.225 388.36 553.167 391.79 556.383 397.36ZM557.593 44.7554L625.011 161.526C628.226 167.096 628.226 173.957 625.011 179.526L557.593 296.297C554.377 301.867 548.435 305.297 542.004 305.297H407.169C400.738 305.297 394.796 301.866 391.58 296.297L324.163 179.526C320.947 173.957 320.947 167.096 324.163 161.526L391.58 44.7554C394.796 39.1861 400.738 35.7554 407.169 35.7554H542.004C548.435 35.7554 554.377 39.1861 557.593 44.7554Z" stroke={PRIMARY_DARK} strokeWidth="34" />
      </svg>
    </Box>
  );
}
