import { useState } from 'react'
import { Stack, Button, TextField } from '@material-ui/core';
import { LoadingButton, DatePicker } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import ModalContainer from '../../shared/modal/ModalContainer'
import { useSnackbar } from 'notistack5';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Compliance } from '../../../@types/compliance'
import { useUpdateComplianceReminderMutation } from '../../../services/vehicles'

type ComplianceReminderScheduleProps = {
  compliance: Compliance;
  vehicleId: string;
  loading: boolean;
  disabled: boolean;
};

export default function ComplianceReminderSchedule({ vehicleId, disabled }: ComplianceReminderScheduleProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [updateComplianceReminder] = useUpdateComplianceReminderMutation();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  type ComplianceUpdateFormValues = {
    nextComplianceReminderDate: Date;
  };

  const onSubmit = async (data: ComplianceUpdateFormValues) => {

    try {
      // dispatch the compliance update
      await updateComplianceReminder({
        vehicleId: vehicleId,
        nextComplianceReminderDate: data.nextComplianceReminderDate
      }).unwrap();

      enqueueSnackbar('Compliance reminder update success', { variant: 'success' });
      setConfirmDeleteOpen(false);
    } catch (response) {
      // process for errors
      if (response.data && response.data.errors) {
        const apiErrors = response.data.errors;
        Object.keys(apiErrors).forEach((key: any) => {
          let errors: string[] = Object.keys(apiErrors[key]).map((errorKey: string) => {
            return apiErrors[key][errorKey];
          })

          // set the erorrs on the form
          setError(key, {
            type: "manual",
            message: errors.join(',')
          })
        })
      }
      else {
        enqueueSnackbar('Error occurred updating compliance reminder', { variant: 'error' });
      }
    }
  };

  const FormSchema = Yup.object().shape({
    nextComplianceReminderDate: Yup.date()
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty }
  } = useForm<ComplianceUpdateFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      nextComplianceReminderDate: ''
    }
  })

  return (
    <>
      <Button
        size="small"
        disabled={disabled}
        color="secondary"
        startIcon={<Icon icon={clockFill} />}
        onClick={() => setConfirmDeleteOpen(true)}
      >
        Schedule Reminder
      </Button>
      <ModalContainer
        title="Set Next Compliance Reminder"
        open={confirmDeleteOpen}
        component={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Controller
                name="nextComplianceReminderDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Next Reminder Date"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={Boolean(error)}
                        helperText={Boolean(error) ? error?.message : 'This will set the date for when the compliance reminder will be sent automatically. It is recommended to set this date a couple of weeks prior to the compliance due date'}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isDirty}
              >
                Update Reminder
              </LoadingButton>
            </Stack>
          </form>
        }
        handleConfirmModalClose={() => setConfirmDeleteOpen(false)}
      />
    </>
  );
}

