import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack5';
import { Stack, Typography, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch } from '../../../redux/store';
import * as Yup from 'yup';
import { updateClientConfiguration } from '../../../redux/slices/client'
import { ComplianceTypeConfiguration } from '../../../@types/compliance'

type ClientComplianceTrackingSettingsFormProps = {
    projectIdentifer: string;
    clientId: string;
    complianceConfiguration: ComplianceTypeConfiguration;
    complianceTypeCode: 'WOF' | 'COF';
    onSettingsUpdated?(): void;
    onUpdateCancelled?(): void;
};

type ComplianceSettingsFormValues = {
    complianceTrackingEnabled: boolean;
    applyTrackingUpdate: boolean;
};

export const FormSchema = Yup.object().shape({
    complianceTrackingEnabled: Yup.boolean().required(),
});

export default function ClientComplianceTrackingSettingsForm({
    projectIdentifer,
    clientId,
    complianceConfiguration,
    onSettingsUpdated,
    onUpdateCancelled,
    complianceTypeCode
}: ClientComplianceTrackingSettingsFormProps) {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {
        watch,
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid, dirtyFields }
    } = useForm<ComplianceSettingsFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            complianceTrackingEnabled: complianceConfiguration.trackingEnabled,
            applyTrackingUpdate: false,
        }
    })

    const cancel = () => {
        reset();
        onUpdateCancelled && onUpdateCancelled();
    }

    const onSubmit = async (data: ComplianceSettingsFormValues) => {

        try {
            // post the configuration
            await dispatch(updateClientConfiguration({
                projectIdentifer: projectIdentifer,
                clientId: clientId,
                payload: {
                    level: 2,
                    levelIdentifier: clientId,
                    complianceType: complianceTypeCode,
                    complianceTrackingConfig: {
                        complianceTrackingEnabled: data.complianceTrackingEnabled,
                        applyTrackingUpdate: data.applyTrackingUpdate,
                    }
                }
            })).unwrap()
            
            enqueueSnackbar('Vehicle settings updated', { variant: 'success' });
            reset(data);

            if (onSettingsUpdated) {
                onSettingsUpdated()
            }
        }
        catch (err) {
            enqueueSnackbar('Error occurred updating client settings', { variant: 'error' });
        }
    };

    const complianceTrackingEnabledWatch = watch('complianceTrackingEnabled');

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <Typography variant="body2">
                        Compliance tracking allows vehicle compliances to subscribe to updates from NZTA and ensures that all compliance information is kept up to date automatically. This feature may incur additional costs if subscription thresholds are exceeded.
                        Settings at this level will apply to all vehicles that this client is the owner of.
                    </Typography>
                    <Controller
                        name="complianceTrackingEnabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label="Compliance Tracking Enabled"
                                control={
                                    <Checkbox
                                        {...field}
                                        onChange={e => field.onChange(e.target.checked)}
                                        checked={field.value}
                                    />
                                }
                            />
                        )}
                    />
                    { dirtyFields && dirtyFields['complianceTrackingEnabled'] ? 
                        <>
                            <Typography variant="body2">
                                You have changed the compliance tracking setting for this client. You have the option to apply this update to all compliances
                                associated with this client.
                            </Typography>
                            <Controller
                                name="applyTrackingUpdate"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label={complianceTrackingEnabledWatch == true ? 'Enable compliance tracking for all active compliances' : 'Disable compliance tracking for all active compliances'}
                                        control={
                                            <Checkbox
                                                {...field}
                                                onChange={e => field.onChange(e.target.checked)}
                                                checked={field.value}
                                            />
                                        }
                                    />
                                )}
                            />
                        </>
                    : null}
                </Stack>
                <Box sx={{ mt: 2 }}>
                    <LoadingButton
                        //fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isDirty || !isValid}
                    >
                        Update
                    </LoadingButton>
                    <LoadingButton
                        size="large"
                        color="error"
                        variant="text"
                        sx={{ ml: 1 }}
                        onClick={() => cancel()}
                    >
                        Cancel
                    </LoadingButton>
                </Box>
            </form>
        </>
    );
}