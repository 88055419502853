import { AllocationType, AllocationSummary } from './allocation'
import { ISearchCriteria, ISearchResult } from './search'
import { Vehicle } from './vehicle'
import { IConfigurationUpdateRequest } from './configuration'

export enum ComplianceStatus {
    Passed = "Passed",
    Failed = "Failed",
    Expired = "Expired",
    Cancelled = "Cancelled"
}

export enum ComplianceType {
    Unknown = "Unknown",
    WOF = "WOF",
    COF = "COF",
}

export interface Compliance {
    updated: string;
    expiryDate?: Date | null;
    lastInspectionDate?: Date | null;
    timezone: string;
    status: ComplianceStatus;
    note: string;
    client: string;
    type: ComplianceType;
    history: ComplianceHistory[];
    notification: ComplianceNotification;
    tracking: ComplianceTracking;
}

export interface ComplianceHistory {
    created: string;
    expiryDate: string;
    lastInspectionDate: string;
    status: ComplianceStatus;
    note: string;
    audit: string;
    user?: {
        displayName: string
    }
}

export interface ComplianceNotification {
    whenNotificationScheduled: Date | null;
    whenNotificationLastSent: Date | null;
    lastManualReminderSent: Date | null;
    notifications: ComplianceNotificationAudit[];
}

export interface ComplianceNotificationAudit {
    whenNotificationSent: Date;
    status: string;
    message: string;
}

export interface ComplianceUpdateRequest {
    vehicleId: string;
    lastComplianceInspectionDate: Date | null;
    complianceExpiryDate: Date | null;
    newStatus: ComplianceStatus;
    note: string;
}

export interface ComplianceReminderUpdateRequest {
    vehicleId: string;
    nextComplianceReminderDate: Date | null;
}

export interface ComplianceCreateRequest {
    vehicleId: string;
    lastComplianceInspectionDate: Date;
    complianceExpiryDate: Date | null;
    newStatus: ComplianceStatus;
    createComplianceReminder: boolean;
    assignToMe: boolean;
    note: string;
}

export type ComplianceConfiguration = {
    createComplianceOnVehicleCreation: boolean;
    complianceTrackingEnabled: boolean;
    wofConfiguration: ComplianceTypeConfiguration;
    cofConfiguration: ComplianceTypeConfiguration;
}

export type ComplianceTypeConfiguration = {
    enabled: boolean;
    trackingEnabled: boolean;
    reminderEmailConfiguration: ComplianceEmailReminderConfiguration;
    reminderConfiguration: ComplianceReminderConfiguration;
}

export type ComplianceReminderConfiguration = {
    createComplianceReminders: boolean;
    complianceTrackingEnabled: boolean;
    defaultAllocation: AllocationSummary;
    defaultAllocationId: string;
    defaultAllocationType: AllocationType;
}

export type ComplianceEmailReminderConfiguration = {
    enabled: boolean;
    sendReminderToClient: boolean;
    reminderFromName: string;
    emailBody: string;
    reminderAdditionalEmail: string;
    reminderReplyToEmail: string;
}

export type ComplianceTracking = {
    enabled: boolean;
}

export interface IComplianceEmailReminderConfigurationUpdateRequest extends IConfigurationUpdateRequest {
    enabled: boolean;
    sendReminderToClient: boolean;
    reminderFromName: string;
    emailBody: string;
    reminderAdditionalEmail: string;
    reminderReplyToEmail: string;
}

export interface IComplianceSearchCriteria extends ISearchCriteria {
    complianceExpiryTo: Date | null;
    complianceExpiryFrom: Date | null;
    statusList: ComplianceStatus[];
    trackedOnly: boolean;
}

export interface IComplianceSearchResult extends ISearchResult<Vehicle> {

}