import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

export default function Logo({ sx, width = 200, height = 45 }: BoxProps & { width?: number, height?: number }) {
    
    const theme = useTheme();
    const PRIMARY_DARK = theme.palette.primary.dark;

    return (
        <Box sx={{ width: width, height: height, ...sx }}>
            <svg width={width} height={height} viewBox="0 0 3039 697" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M689.449 233.876L633.834 330.204C627.582 341.033 627.582 354.375 633.834 365.204L689.449 461.532M115.344 130.828L226.574 130.828C239.078 130.828 250.633 124.157 256.885 113.328L312.5 16.9999M312.5 679.514L256.885 583.186C250.633 572.357 239.078 565.686 226.574 565.686H115.344" stroke="#C5C5C5" stroke-width="34" stroke-linecap="round" />
                <path d="M252.842 222.11L320.259 338.881C323.475 344.45 323.475 351.312 320.259 356.881L252.842 473.652C249.626 479.221 243.684 482.652 237.253 482.652H102.418C95.987 482.652 90.0447 479.221 86.8293 473.652L19.4115 356.881C16.1962 351.312 16.1962 344.45 19.4115 338.881L86.8293 222.11C90.0447 216.541 95.987 213.11 102.418 213.11H237.253C243.684 213.11 249.626 216.541 252.842 222.11ZM556.383 397.36L623.801 514.131C627.016 519.7 627.016 526.561 623.801 532.131L556.383 648.901C553.167 654.471 547.225 657.902 540.794 657.902H405.959C399.528 657.902 393.586 654.471 390.37 648.902L322.953 532.131C319.737 526.561 319.737 519.7 322.953 514.131L390.37 397.36C393.586 391.79 399.528 388.36 405.959 388.36H540.794C547.225 388.36 553.167 391.79 556.383 397.36ZM557.593 44.7554L625.011 161.526C628.226 167.096 628.226 173.957 625.011 179.526L557.593 296.297C554.377 301.867 548.435 305.297 542.004 305.297H407.169C400.738 305.297 394.796 301.866 391.58 296.297L324.163 179.526C320.947 173.957 320.947 167.096 324.163 161.526L391.58 44.7554C394.796 39.1861 400.738 35.7554 407.169 35.7554H542.004C548.435 35.7554 554.377 39.1861 557.593 44.7554Z" stroke={PRIMARY_DARK} stroke-width="34" />
                <path d="M914.608 109.71C923.068 89.0299 937.168 75.8699 966.308 75.8699C994.978 75.8699 1009.08 89.0299 1017.54 109.24L1135.04 395H1073L1046.21 326.85H885.468L858.678 395H797.108L914.608 109.71ZM902.858 282.67H1028.82L972.888 139.79C970.538 134.62 967.718 133.68 966.308 133.68C964.428 133.68 961.608 134.62 959.258 139.79L902.858 282.67Z" fill={PRIMARY_DARK} />
                <path d="M1178.55 298.65V160H1231.66V296.77C1231.66 338.6 1250.46 356.93 1295.11 356.93C1338.82 356.93 1357.62 338.6 1357.62 296.77V160H1410.73V298.65C1410.73 368.68 1375.95 399.23 1295.11 399.23C1213.33 399.23 1178.55 368.68 1178.55 298.65Z" fill={PRIMARY_DARK} />
                <path d="M1598.86 356.93C1614.37 356.93 1628.94 355.52 1649.15 351.76V391.24C1624.71 395.94 1609.2 399.23 1588.52 399.23C1527.42 399.23 1505.33 379.49 1505.33 315.57V201.83H1469.61V160H1505.33V98.8998H1558.44V160H1646.33V201.83H1558.44V313.22C1558.44 348 1566.9 356.93 1598.86 356.93Z" fill={PRIMARY_DARK} />
                <path d="M1703.95 274.68C1703.95 194.31 1744.84 155.77 1828.5 155.77C1911.69 155.77 1952.11 194.31 1952.11 274.68V280.32C1952.11 360.69 1911.69 399.23 1828.5 399.23C1744.84 399.23 1703.95 360.69 1703.95 280.32V274.68ZM1757.06 278.91C1757.06 333.9 1778.21 356.93 1828.5 356.93C1878.32 356.93 1899 333.9 1899 278.91V276.09C1899 221.1 1878.32 198.07 1828.5 198.07C1778.21 198.07 1757.06 221.1 1757.06 276.09V278.91Z" fill={PRIMARY_DARK} />
                <path d="M2022.53 80.0999H2077.05V215.46H2258.94V80.0999H2313.46V395H2258.94V259.64H2077.05V395H2022.53V80.0999Z" fill={PRIMARY_DARK} />
                <path d="M2390.22 160H2443.33V395H2390.22V160ZM2383.64 89.9698V89.0299C2383.64 71.1699 2395.39 60.3599 2417.01 60.3599C2437.69 60.3599 2449.44 71.1699 2449.44 89.0299V89.9698C2449.44 107.36 2437.69 118.17 2417.01 118.17C2395.39 118.17 2383.64 107.36 2383.64 89.9698Z" fill={PRIMARY_DARK} />
                <path d="M2501.7 160H2559.04L2625.78 339.07C2627.66 343.77 2629.54 344.24 2631.42 344.24C2633.3 344.24 2635.65 343.77 2637.06 339.07L2703.8 160H2760.67L2678.42 365.86C2668.08 391.24 2656.8 399.23 2631.42 399.23C2606.04 399.23 2594.29 391.24 2584.42 365.86L2501.7 160Z" fill={PRIMARY_DARK} />
                <path d="M3038.67 268.1C3038.67 293.01 3034.91 298.65 3016.58 298.65H2854.43C2860.07 340.01 2883.57 357.4 2933.39 357.4C2957.36 357.4 2997.31 353.17 3019.87 348.47V388.42C2987.44 395.47 2955.48 399.23 2928.69 399.23C2841.27 399.23 2800.38 361.63 2800.38 280.32V274.68C2800.38 194.31 2840.33 155.77 2921.64 155.77C3001.07 155.77 3038.67 196.66 3038.67 268.1ZM2853.96 259.64H2985.09C2984.62 219.69 2964.41 197.6 2921.64 197.6C2878.4 197.6 2858.19 216.4 2853.96 259.64Z" fill={PRIMARY_DARK} />
                <path d="M848.348 602.925C846.178 610.52 842.303 614.395 834.398 614.395C826.338 614.395 822.773 610.21 820.603 602.925L791.773 509.15H809.133L833.623 592.85C833.933 593.935 834.243 594.4 834.863 594.4C835.328 594.4 835.638 593.935 835.948 592.85L856.098 519.535C857.958 512.715 861.058 507.755 869.583 507.755C878.108 507.755 881.053 512.715 882.913 519.535L903.218 592.85C903.528 594.09 903.838 594.4 904.303 594.4C904.768 594.4 905.078 594.245 905.543 592.85L930.033 509.15H947.238L918.563 602.925C916.393 610.21 912.673 614.395 904.613 614.395C896.863 614.395 892.988 610.52 890.818 602.925L870.668 530.075C870.513 529.3 870.048 528.835 869.583 528.835C869.118 528.835 868.653 529.3 868.498 530.075L848.348 602.925Z" fill={PRIMARY_DARK} />
                <path d="M1014.5 614.395C978.848 614.395 961.178 596.725 961.178 561.075C961.178 525.425 978.848 507.755 1014.5 507.755C1050.15 507.755 1067.82 525.425 1067.82 561.075C1067.82 596.725 1050.15 614.395 1014.5 614.395ZM977.298 561.075C977.298 587.735 989.698 601.065 1014.5 601.065C1039.3 601.065 1051.7 587.735 1051.7 561.075C1051.7 534.415 1039.3 521.085 1014.5 521.085C989.698 521.085 977.298 534.415 977.298 561.075Z" fill={PRIMARY_DARK} />
                <path d="M1143.58 509.15C1168.38 509.15 1181.09 519.07 1181.09 541.545C1181.09 559.68 1173.03 570.53 1156.6 573.94L1184.19 613H1164.97L1138.47 575.18H1107.47V613H1091.81V509.15H1143.58ZM1107.47 562.47H1143.58C1157.69 562.47 1164.51 555.185 1164.51 541.545C1164.51 527.595 1157.38 521.86 1143.58 521.86H1107.47V562.47Z" fill={PRIMARY_DARK} />
                <path d="M1206.62 509.15H1222.27V554.72H1231.57L1276.37 509.15H1295.74L1245.52 561.23L1299.77 613H1278.69L1231.57 567.43H1222.27V613H1206.62V509.15Z" fill={PRIMARY_DARK} />
                <path d="M1398.48 527.13C1386.24 522.945 1373.84 521.085 1361.9 521.085C1341.6 521.085 1331.83 526.51 1331.83 536.585C1331.83 545.42 1339.12 549.915 1360.35 553.79C1393.21 559.835 1404.22 568.05 1404.22 584.79C1404.22 604.165 1389.49 614.395 1358.03 614.395C1344.54 614.395 1329.97 612.535 1317.57 608.815V595.02C1329.66 598.74 1344.23 601.065 1357.72 601.065C1378.49 601.065 1387.63 595.64 1387.63 585.565C1387.63 576.575 1380.19 572.235 1358.03 568.205C1325.94 562.47 1315.25 554.1 1315.25 537.67C1315.25 517.365 1331.37 507.755 1361.44 507.755C1374.77 507.755 1387.63 509.615 1398.48 513.335V527.13Z" fill={PRIMARY_DARK} />
                <path d="M1427.14 509.15H1442.8V554.72H1505.57V509.15H1521.23V613H1505.57V567.43H1442.8V613H1427.14V509.15Z" fill={PRIMARY_DARK} />
                <path d="M1598.5 614.395C1562.85 614.395 1545.18 596.725 1545.18 561.075C1545.18 525.425 1562.85 507.755 1598.5 507.755C1634.15 507.755 1651.82 525.425 1651.82 561.075C1651.82 596.725 1634.15 614.395 1598.5 614.395ZM1561.3 561.075C1561.3 587.735 1573.7 601.065 1598.5 601.065C1623.3 601.065 1635.7 587.735 1635.7 561.075C1635.7 534.415 1623.3 521.085 1598.5 521.085C1573.7 521.085 1561.3 534.415 1561.3 561.075Z" fill={PRIMARY_DARK} />
                <path d="M1730.38 509.15C1755.49 509.15 1767.89 520.62 1767.89 543.715C1767.89 566.81 1755.49 578.28 1730.38 578.28H1691.47V613H1675.82V509.15H1730.38ZM1691.47 565.57H1730.38C1744.33 565.57 1751.3 558.285 1751.3 543.715C1751.3 529.145 1744.33 521.86 1730.38 521.86H1691.47V565.57Z" fill={PRIMARY_DARK} />
                <path d="M1835.92 509.15H1852.04L1892.65 574.405C1893.43 575.645 1894.98 575.645 1895.75 574.405L1936.36 509.15H1952.33V613H1936.67V535.965L1905.36 585.72C1902.26 590.525 1899.01 592.695 1894.05 592.695C1889.09 592.695 1885.83 590.525 1882.73 585.72L1851.42 535.965V613H1835.92V509.15Z" fill={PRIMARY_DARK} />
                <path d="M2010.67 518.14C2013.15 512.095 2017.49 507.755 2026.64 507.755C2035.78 507.755 2040.12 512.095 2042.6 518.14L2081.2 613H2063.53L2054.23 589.595H1999.05L1989.75 613H1972.08L2010.67 518.14ZM2004.16 576.885H2049.11L2028.96 526.045C2028.19 524.03 2027.1 523.875 2026.64 523.875C2026.17 523.875 2025.09 524.03 2024.31 526.045L2004.16 576.885Z" fill={PRIMARY_DARK} />
                <path d="M2101.26 509.15H2116.45L2180.15 590.06V509.15H2195.34V613H2180.15L2116.45 532.09V613H2101.26V509.15Z" fill={PRIMARY_DARK} />
                <path d="M2253.9 518.14C2256.38 512.095 2260.72 507.755 2269.87 507.755C2279.01 507.755 2283.35 512.095 2285.83 518.14L2324.43 613H2306.76L2297.46 589.595H2242.28L2232.98 613H2215.31L2253.9 518.14ZM2247.39 576.885H2292.34L2272.19 526.045C2271.42 524.03 2270.33 523.875 2269.87 523.875C2269.4 523.875 2268.32 524.03 2267.54 526.045L2247.39 576.885Z" fill={PRIMARY_DARK} />
                <path d="M2433.88 528.37C2423.49 523.565 2409.23 521.085 2395.9 521.085C2366.14 521.085 2352.81 533.33 2352.81 560.92C2352.81 588.51 2366.14 601.065 2394.82 601.065C2403.81 601.065 2412.8 599.825 2420.55 598.12V567.43H2395.75V554.72H2436.2V598.895C2436.2 604.94 2432.95 608.66 2427.37 610.365C2422.25 611.915 2407.22 614.395 2393.11 614.395C2355.45 614.395 2336.69 596.725 2336.69 560.765C2336.69 524.805 2355.45 507.755 2394.97 507.755C2411.4 507.755 2425.51 510.7 2433.88 514.575V528.37Z" fill={PRIMARY_DARK} />
                <path d="M2461.73 509.15H2543.26V521.86H2477.38V554.72H2540.62V567.43H2477.38V586.805C2477.38 594.555 2483.12 600.29 2490.87 600.29H2543.26V613H2486.99C2472.42 613 2461.73 602.925 2461.73 589.285V509.15Z" fill={PRIMARY_DARK} />
                <path d="M2567.73 509.15H2583.85L2624.46 574.405C2625.23 575.645 2626.78 575.645 2627.56 574.405L2668.17 509.15H2684.13V613H2668.48V535.965L2637.17 585.72C2634.07 590.525 2630.81 592.695 2625.85 592.695C2620.89 592.695 2617.64 590.525 2614.54 585.72L2583.23 535.965V613H2567.73V509.15Z" fill={PRIMARY_DARK} />
                <path d="M2711.01 509.15H2792.54V521.86H2726.67V554.72H2789.91V567.43H2726.67V586.805C2726.67 594.555 2732.4 600.29 2740.15 600.29H2792.54V613H2736.28C2721.71 613 2711.01 602.925 2711.01 589.285V509.15Z" fill={PRIMARY_DARK} />
                <path d="M2817.32 509.15H2832.51L2896.22 590.06V509.15H2911.41V613H2896.22L2832.51 532.09V613H2817.32V509.15Z" fill={PRIMARY_DARK} />
                <path d="M3027.31 509.15V521.86H2988.41V613H2972.75V521.86H2933.85V509.15H3027.31Z" fill={PRIMARY_DARK} />
            </svg>

        </Box>
    );
}
