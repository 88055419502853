import { useState } from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Icon } from '@iconify/react';
import ClientComplianceTrackingSettingsForm from './form/ClientComplianceTrackingSettingsForm'
import ClientComplianceEmailSettings from './form/ClientComplianceEmailSettings'
import { LoadingButton } from '@material-ui/lab';
import { useSelector } from '../../redux/store';
import { selectClientComplianceConfiguration, selectClientComplianceConfigurationOverrides } from '../../redux/slices/client';
import { styled } from '@material-ui/core/styles';

type ClientSettingsProps = {
    projectIdentifier: string | undefined;
    clientId: string
};

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between',
    '&:not(:last-of-type)':  {
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.grey[200],
    }
  }));
  
const TitleWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'calc(100% - 120px)',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '25%',
    },
}));

const ValueWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'calc(100% - 120px)',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '50%',
    },
}));

const EditWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'flex-end',
    minWidth: 120,
    maxWidth: '25%',
}));

type SettingItemProps = {
    settingName: string;
    settingValue: string;
    open: boolean;
    overriden?: boolean;
    actionLinkText?: string;
    onOpenEdit(): void;
    editComponent(overriden: boolean): React.ReactFragment;
};

function SettingItem({ settingName, settingValue, editComponent, open, onOpenEdit, overriden, actionLinkText = 'Edit' }: SettingItemProps) {
    
    return (
        <RootStyle>
          <TitleWrapperStyle>
            <Typography variant="subtitle1">
                {settingName}                
            </Typography>
          </TitleWrapperStyle>
          <ValueWrapperStyle>
            { open ?  editComponent(overriden || false) : <Typography variant="body1">{settingValue}{overriden ? ' (overidden)' : ''}</Typography>}
          </ValueWrapperStyle>
          <EditWrapperStyle >
            <LoadingButton
                sx={{ display: open ? 'none' : 'inline-flex'}}
                size="large"
                type="submit"
                variant="outlined"
                onClick={() => onOpenEdit()}
            >
                {actionLinkText}
            </LoadingButton>
          </EditWrapperStyle>
        </RootStyle>
    );
}

export default function ClientSettings({ clientId, projectIdentifier }: ClientSettingsProps) {
    
    const [open, setOpen] = useState(false);
    const [emailsOpen, setEmailsOpen] = useState(false);
    const [cofTrackingOpen, setCofTrackingOpen] = useState(false);
    const [cofRemindersOpen, setCofRemindersOpen] = useState(false);
    const complianceConfiguration = useSelector(selectClientComplianceConfiguration);
    const configurationOverrides = useSelector(selectClientComplianceConfigurationOverrides);

    var wofTrackingEnabled = configurationOverrides.find((override) => override.propertyPath === "FleetProjectConfiguration.ComplianceConfiguration.WofConfiguration.TrackingEnabled");
    var cofTrackingEnabled = configurationOverrides.find((override) => override.propertyPath === "FleetProjectConfiguration.ComplianceConfiguration.CofConfiguration.TrackingEnabled");

    if (projectIdentifier == null)
    {
        return null;
    }

    if (!complianceConfiguration)
    {
        return null;
    }

    return (
        <>
        <Accordion key={'compliance'} defaultExpanded={true}>
          <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={30} height={30} />} >
            <Typography variant="h5">Warrant of Fitness (WOF) Settings</Typography>  
          </AccordionSummary>
          <AccordionDetails>
          <SettingItem
                settingName="Compliance Tracking"
                overriden={wofTrackingEnabled?.overriden}
                settingValue={complianceConfiguration.wofConfiguration.trackingEnabled ? "Enabled" : "Disabled"}
                open={open}
                onOpenEdit={() => setOpen(!open)}
                editComponent={() => 
                <>
                    <ClientComplianceTrackingSettingsForm
                        projectIdentifer={projectIdentifier}
                        clientId={clientId}
                        complianceTypeCode="WOF"
                        complianceConfiguration={complianceConfiguration.wofConfiguration}
                        onSettingsUpdated={() => setOpen(false)}
                        onUpdateCancelled={() => setOpen(false)}
                    />
                </>
                }
            />
            <SettingItem
                settingName="Email Reminders"
                settingValue={complianceConfiguration.wofConfiguration.reminderEmailConfiguration.enabled ? `Compliance expiry emails are enabled ${complianceConfiguration.wofConfiguration.reminderEmailConfiguration.sendReminderToClient ? 'and configured to be sent to the client' : ' but will not be sent to this client'}` : "Disabled"}
                open={emailsOpen}
                actionLinkText="Configure"
                onOpenEdit={() => setEmailsOpen(!emailsOpen)}
                editComponent={() => 
                <>
                    <ClientComplianceEmailSettings
                        projectIdentifer={projectIdentifier}
                        clientId={clientId}
                        complianceTypeCode="WOF"
                        complianceConfiguration={complianceConfiguration.wofConfiguration}
                        onSettingsUpdated={() => setEmailsOpen(false)}
                        onUpdateCancelled={() => setEmailsOpen(false)}
                    />
                </>
                }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={'general'} defaultExpanded={true}>
          <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={30} height={30} />}>
            <Typography variant="h5">Certificate of Fitness (COF) Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <SettingItem
                settingName="Compliance Tracking"
                settingValue={complianceConfiguration.cofConfiguration.trackingEnabled ? "Enabled" : "Disabled"}
                overriden={cofTrackingEnabled?.overriden}
                open={cofTrackingOpen}
                onOpenEdit={() => setCofTrackingOpen(!cofTrackingOpen)}
                editComponent={() => 
                <>
                    <ClientComplianceTrackingSettingsForm
                        projectIdentifer={projectIdentifier}
                        clientId={clientId}
                        complianceTypeCode="COF"
                        complianceConfiguration={complianceConfiguration.cofConfiguration}
                        onSettingsUpdated={() => setCofTrackingOpen(false)}
                        onUpdateCancelled={() => setCofTrackingOpen(false)}
                    />
                </>
                }
            />
            <SettingItem
                settingName="Reminders"
                settingValue={complianceConfiguration.cofConfiguration.reminderEmailConfiguration.enabled ? `Compliance expiry reminder emails are enabled ${complianceConfiguration.cofConfiguration.reminderEmailConfiguration.sendReminderToClient ? 'and configured to be sent to the client' : ' but will not be sent to this client'}` : "Disabled"}
                open={cofRemindersOpen}
                onOpenEdit={() => setCofRemindersOpen(!cofRemindersOpen)}
                editComponent={() => 
                <>
                    <ClientComplianceEmailSettings
                        projectIdentifer={projectIdentifier}
                        clientId={clientId}
                        complianceTypeCode="COF"
                        complianceConfiguration={complianceConfiguration.cofConfiguration}
                        onSettingsUpdated={() => setCofRemindersOpen(false)}
                        onUpdateCancelled={() => setCofRemindersOpen(false)}
                    />
                </>
                }
            />
          </AccordionDetails>
        </Accordion>
        </>
    );
}


