import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles';
import { Stack, CardHeader, Card, CardContent, Typography, Button, Skeleton, Link, Alert } from '@material-ui/core';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/email-fill';
import { Compliance } from '../../../@types/compliance'
import { fDateTime, fDate } from '../../../utils/formatTime'
import { ComplianceEditModal } from '../index'
import ComplianceReminderSchedule from './ComplianceReminderSchedule'
import ComplianceReminderHistoryModal from './ComplianceReminderHistoryModal'
import ConfirmModal from '../../shared/modal/ConfirmModal'
import useProject from '../../../hooks/useProject'
import { useSnackbar } from 'notistack5';
import { useSendComplianceReminderMutation } from '../../../services/vehicles'

type ComplianceReminderButtonProps = {
  compliance: Compliance;
  vehicleId: string;
  loading: boolean;
  disabled: boolean;
};

function ComplianceReminderButton({ compliance, vehicleId, disabled }: ComplianceReminderButtonProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [reminderSending, setReminderSending] = useState(false);
  const [sendComplianceReminder] = useSendComplianceReminderMutation();

  const sendReminder = async (compliance: Compliance) => {
    try {
      setReminderSending(true);
      await sendComplianceReminder(vehicleId).unwrap();
      enqueueSnackbar('Compliance reminder sent successfully!', { variant: 'success' });
      setReminderModalOpen(false);
      setReminderSending(false);
    } catch (response) {

      setReminderSending(false);
      if (response.data && response.data.errors) {
        const apiErrors = response.data.errors;
        enqueueSnackbar(apiErrors[0], { variant: 'error', autoHideDuration: 6000 });
      }
      setReminderModalOpen(false)
    }
  }

  return (
    <>
      <Button
        size="small"
        disabled={disabled}
        startIcon={<Icon icon={editFill} />}
        onClick={() => setReminderModalOpen(true)}
      >
        Send Reminder
      </Button>
      <ConfirmModal
        title="Confirm Send Compliance Reminder"
        description={"Are you sure you want to send the compliance reminder? This will send the reminder via email to the vehicle owner"}
        confirmButtonText="Send Away"
        confirmLoading={reminderSending}
        buttonColor="success"
        modalOpen={reminderModalOpen}
        handleConfirmModalConfirm={() => sendReminder(compliance)}
        handleConfirmModalClose={() => setReminderModalOpen(false)}
        handleConfirmModalCancel={() => setReminderModalOpen(false)}
      />
    </>
  );
}

type ComplianceReminderProps = {
  vehicleId: string;
  compliance: Compliance | null;
  loading: boolean;
  disabled?: boolean;
};

export default function ComplianceReminder({ vehicleId, compliance, loading, disabled = false }: ComplianceReminderProps) {

  const theme = useTheme();
  const { project } = useProject();
  const [complianceReminderHistoryOpen, setComplianceReminderHistoryOpen] = useState(false)

  if (!compliance || loading) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Compliance Reminder" />
        <CardContent>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Reminder Sent</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Next Reminder Scheduled</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Manual Reminder Sent</Typography>
              <Typography variant="subtitle2" sx={{ width: 130 }}><Skeleton /></Typography>
            </Stack>
          </Stack>
          <Stack spacing={3} alignItems="flex-end" sx={{ pt: 3 }}>
            <Button size="small" disabled={disabled} startIcon={<Icon icon={editFill} />}>
              Send Compliance Reminder
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        title="Compliance Reminder"
        subheader=""
      />
      <CardContent>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Compliance reminders will be sent automatically when enabled. You can also manually send a compliance reminder at anytime.
            For more details on compliance reminders see the <Link sx={{ color: theme.palette.primary.dark }} href={`/group/${project?.identifier}/settings/vehicles`}>settings</Link></Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Reminder Sent</Typography>
          <Typography variant="subtitle2">{compliance.notification.whenNotificationLastSent ? fDateTime(compliance.notification.whenNotificationLastSent) : '-'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Next Reminder Scheduled</Typography>
          <Typography variant="subtitle2">{!disabled ? compliance.notification.whenNotificationScheduled ? fDateTime(compliance.notification.whenNotificationScheduled) : '-' : 'N/A'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>Last Manual Reminder Sent</Typography>
          <Typography variant="subtitle2">{compliance.notification.lastManualReminderSent ? fDateTime(compliance.notification.lastManualReminderSent) : '-'}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="end" sx={{ display: compliance.notification.notifications ? 'flex' : 'none' }}>
          <Typography variant="subtitle2" sx={{ cursor: 'pointer', color: theme.palette.primary.dark, mt: 1 }}>
            <Link onClick={() => setComplianceReminderHistoryOpen(true)}>View History</Link>
          </Typography>
          <ComplianceReminderHistoryModal
            modalOpen={complianceReminderHistoryOpen}
            handleModalClose={() => setComplianceReminderHistoryOpen(false)}
            compliance={compliance}
          />
        </Stack>

        <Stack spacing={3} direction="row-reverse" sx={{ pt: 5 }}>
          <ComplianceReminderButton
            compliance={compliance}
            vehicleId={vehicleId}
            loading={false}
            disabled={disabled}
          />
          <ComplianceReminderSchedule
            compliance={compliance}
            vehicleId={vehicleId}
            loading={false}
            disabled={disabled}
          />
        </Stack>
        <ComplianceEditModal vehicleId={vehicleId} compliance={compliance} />
      </CardContent>
    </Card>
  );
}
