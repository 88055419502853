import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack5';
import { Stack, Typography, Checkbox, FormControlLabel, TextField, Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import axios from '../../../utils/axios'
import { useDispatch } from '../../../redux/store';
import { updateClientConfiguration } from '../../../redux/slices/client';
import * as Yup from 'yup';
import { ComplianceTypeConfiguration } from '../../../@types/compliance'

type ComplianceSettingsFormProps = {
    projectIdentifer: string;
    clientId: string;
    complianceConfiguration: ComplianceTypeConfiguration;
    complianceTypeCode: 'WOF' | 'COF';
    onSettingsUpdated?(): void;
    onUpdateCancelled?(): void;
};

type ClientComplianceEmailSettingsFormValues = {
    emailReminderFromName: string;
    emailRemindersEnabled: boolean;
    emailReminderSendToClient: boolean;
    emailReminderBody: string;
    reminderAdditionalEmail: string;
    reminderReplyToEmail: string;
};

export const FormSchema = Yup.object().shape({
    emailReminderFromName: Yup.string().nullable(),
    emailRemindersEnabled: Yup.boolean().required(),
    emailReminderSendToClient: Yup.boolean().required(),
    emailReminderBody: Yup.string().nullable(),
    reminderAdditionalEmail: Yup.string().nullable(),
});

export default function ClientComplianceEmailSettingsForm({ projectIdentifer, clientId, complianceTypeCode, complianceConfiguration, onSettingsUpdated, onUpdateCancelled }: ComplianceSettingsFormProps) {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {
        watch,
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<ClientComplianceEmailSettingsFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            emailRemindersEnabled: complianceConfiguration.reminderEmailConfiguration.enabled,
            emailReminderBody: complianceConfiguration.reminderEmailConfiguration.emailBody,
            emailReminderSendToClient: complianceConfiguration.reminderEmailConfiguration.sendReminderToClient,
            emailReminderFromName: complianceConfiguration.reminderEmailConfiguration.reminderFromName,
            reminderAdditionalEmail: complianceConfiguration.reminderEmailConfiguration.reminderAdditionalEmail,
            reminderReplyToEmail: complianceConfiguration.reminderEmailConfiguration.reminderReplyToEmail,
        }
    })

    const cancel = () => {
        reset();
        onUpdateCancelled && onUpdateCancelled();
    }

    const emailRemindersEnabledWatch = watch('emailRemindersEnabled');

    const onSubmit = async (data: ClientComplianceEmailSettingsFormValues) => {

        try {
            // post the configuration
            await dispatch(updateClientConfiguration({
                projectIdentifer: projectIdentifer,
                clientId: clientId,
                payload: {
                    level: 2,
                    levelIdentifier: clientId,
                    complianceType: complianceTypeCode,
                    complianceReminderConfig: {
                        emailRemindersEnabled: data.emailRemindersEnabled,
                        emailReminderBody: data.emailReminderBody,
                        emailReminderSendToClient: data.emailReminderSendToClient,
                        emailReminderFromName: data.emailReminderFromName,
                        reminderAdditionalEmail: data.reminderAdditionalEmail,
                        reminderReplyToEmail: data.reminderReplyToEmail,
                    }
                }
            })).unwrap()

            // load the new configuration state
            enqueueSnackbar('Vehicle settings updated', { variant: 'success' });
            reset(data);

            if (onSettingsUpdated) {
                onSettingsUpdated()
            }
        }
        catch (err) {
            enqueueSnackbar('Error occurred updating client settings', { variant: 'error' });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Email reminders are sent when a vehicle compliance is near its due date. This feature should be enabled if you provide
                    compliance check services so that your clients know when to bring their vehicle to you for an inspection.
                </Typography>
                <Controller
                    name="emailRemindersEnabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label={`Enable ${complianceTypeCode} Email Reminders For Client`}
                            control={
                                <Checkbox
                                    {...field}
                                    onChange={e => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />
                            }
                        />
                    )}
                />
                {emailRemindersEnabledWatch ?
                    <>
                        <Controller
                            name="emailReminderSendToClient"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label={`Send ${complianceTypeCode} Reminder To Client`}
                                    control={
                                        <Checkbox
                                            {...field}
                                            onChange={e => field.onChange(e.target.checked)}
                                            checked={field.value}
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="emailReminderFromName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label={`${complianceTypeCode} Email From Address`}
                                    helperText="This will set the from name for the reminder email that is sent. If not set it will use the name of the project."
                                />
                            )}
                        />
                        <Controller
                            name="reminderReplyToEmail"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label="ReplyTo Email Address"
                                    helperText="This is the email address that will be used when the client replies to the notification. If this is not supplied the project email will be used"
                                />
                            )}
                        />
                        <Controller
                            name="reminderAdditionalEmail"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label="Additional Email Address"
                                    helperText="This is an additional email address to send the reminder to. This will normally be sent to an internal email and used to track the reminders that are being sent to clients."
                                />
                            )}
                        />
                        <Controller
                            name="emailReminderBody"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="outlined-multiline-static"
                                    label={`${complianceTypeCode} Email Body (optional)`}
                                    helperText={`The email will highlight the details of the vehicle and when the ${complianceTypeCode} is due. Populate this section with details about how your client should get in touch to book the inspection`}
                                    multiline
                                    rows={4}
                                />
                            )}
                        />
                    </>
                    : null}
            </Stack>
            <Box sx={{ mt: 2 }}>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Update
                </LoadingButton>
                <LoadingButton
                    size="large"
                    color="error"
                    variant="text"
                    sx={{ ml: 1 }}
                    onClick={() => cancel()}
                >
                    Cancel
                </LoadingButton>
            </Box>
        </form>
    );
}